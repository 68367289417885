import { handleActions, combineActions } from 'redux-actions'
import { getPagesByContentIdFailed, getPagesByContentIdRequest, getPagesByContentIdSuccess } from '../actions'

const defaultState = false

export default handleActions(
  {
    [getPagesByContentIdRequest]() {
      return true
    },
    [combineActions(getPagesByContentIdSuccess, getPagesByContentIdFailed)]() {
      return false
    },
  },
  defaultState
)
